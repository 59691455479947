import React, {useEffect, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import {windowObject} from "../utils/utils";

const HeroSliderStyles = styled.div`
  width: 100%;
  overflow: hidden;
  height: auto;

  .slick-track {
    height: calc(100vh - 32px - 4vw);
    .gatsby-image-wrapper {
        position: static;
    }
  }

  @media screen and (max-width: 1078px) {
    .slick-track {
        height: calc(100vh - 20px - 4vw);
    }
  }

  @media screen and (max-width: 800px){
    .slick-track {
        height: calc(100vh - 23px - 10vw);
    }
  }
  
  @media screen and (max-width: 992px) {
    height: auto;
  }

  @media screen and (max-width: 576px){
    .slick-track {
        height: calc(100vh - 18px - 10vw);
    }
  }
`;

function HeroSlider({ slides }) {
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        pauseOnHover: false
    };

    const [windowWidth, setWindowWidth] = useState('');

    useEffect(() => {
        if (typeof window !== `undefined`) {
            setWindowWidth(window.innerWidth);
        }
    }, []);

    const mapSlides = () => {
      if (windowObject) {
        if(windowWidth > 800) {
          return slides.map((item, index) => <GatsbyImage image={item.slideDesktop.asset.gatsbyImageData} alt={"Alexander Peterson Image"} key={index}/>)
        } else {
          return slides.map((item, index) => <GatsbyImage image={item.slideMobile.asset.gatsbyImageData} alt={"Alexander Peterson Image"} key={index}/>)
        }
      } else {
        return slides.map((item, index) => <GatsbyImage image={item.slideDesktop.asset.gatsbyImageData} alt={"Alexander Peterson Image"} key={index}/>)
      }
    }

    return (
        <HeroSliderStyles>
            <Slider {...sliderSettings}>
            {mapSlides()}
            </Slider>
        </HeroSliderStyles>
    )
}

export default HeroSlider;
