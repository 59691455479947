import React from "react";
import HeroSlider from "../components/HeroSlider";
import {graphql} from "gatsby";
import SEO from "../components/SEO";

function HomePage({data}){
    const slides = data.slider.nodes;
    return (
        <>
            <SEO />
            <HeroSlider slides={slides}/>
        </>
    )
}

export default HomePage;

export const slides = graphql`
    query SlidesQuery {
        slider: allSanityHeroSlider {
                    nodes {
                      slideDesktop {
                        asset {
                          gatsbyImageData(
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                          )
                        }
                      }
                        slideMobile {
                            asset {
                                gatsbyImageData
                            }
                        }
                      }
        }
    }
`;
